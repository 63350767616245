const ReferenceItem = () => {
    return (
        <div>
            <div className="reference">
                <img className="w-100" src="/img/reference.jpg" alt="team" />
            </div>
        </div>
    )
}

export default ReferenceItem;